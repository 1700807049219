import { CYPRESS_MNEMONIC, IS_PRODUCTION } from '@/config/constants'

const nonProductionURLs = "https://staging.d2nte1x642bbw8.amplifyapp.com https://pulsechain.d3umazlc3yeh3p.amplifyapp.com https://rpc.v4.testnet.pulsechain.com https://gnosis-stage.pulsechainsafe.com https://gnosis-dev.pulsechainsafe.com https://dev.dez5aqpx5uwb2.amplifyapp.com https://pulsechain.d2vzn6z44w9ikm.amplifyapp.com https://main.d3f533tguby7.amplifyapp.com https://pulsechain.dx063j4op8551.amplifyapp.com https://gnosis-preprod.pulsechainsafe.com https://scan.v4.testnet.pulsechain.com https://api.scan.v4.testnet.pulsechain.com  https://wrapper-testnet.pulsechainsafe.com https://wrapper-mainnet.pulsechainsafe.com https://gnosis-testnet.pulsechainsafe.com http://gnosis-testnet.pulsechainsafe.com https://testnet-pulsechainsafe.com https://pulsechain.d1sj5vsjv47wkz.amplifyapp.com https://beacon.v4.testnet.pulsechain.com"
// const productionURLs = "https://www.pulsechainsafe.com https://api.pulsechainsafe.com https://rpc.pulsechain.com https://tx-builder-prod.pulsechainsafe.com https://main.d9u5x197c3cmu.amplifyapp.com https://scan.pulsechain.com https://wrapper-mainnet.pulsechainsafe.com https://api.scan.pulsechain.com"
const productionURLs = "https://staging.d2nte1x642bbw8.amplifyapp.com https://pulsechain.d3umazlc3yeh3p.amplifyapp.com https://rpc.v4.testnet.pulsechain.com https://gnosis-stage.pulsechainsafe.com https://gnosis-dev.pulsechainsafe.com https://dev.dez5aqpx5uwb2.amplifyapp.com https://pulsechain.d2vzn6z44w9ikm.amplifyapp.com https://main.d3f533tguby7.amplifyapp.com https://pulsechain.dx063j4op8551.amplifyapp.com https://gnosis-preprod.pulsechainsafe.com https://scan.v4.testnet.pulsechain.com https://api.scan.v4.testnet.pulsechain.com  https://wrapper-testnet.pulsechainsafe.com https://wrapper-mainnet.pulsechainsafe.com https://gnosis-testnet.pulsechainsafe.com http://gnosis-testnet.pulsechainsafe.com https://testnet-pulsechainsafe.com https://pulsechain.d1sj5vsjv47wkz.amplifyapp.com https://beacon.v4.testnet.pulsechain.com https://www.pulsechainsafe.com https://api.pulsechainsafe.com https://rpc.pulsechain.com https://tx-builder-prod.pulsechainsafe.com https://main.d9u5x197c3cmu.amplifyapp.com https://scan.pulsechain.com https://wrapper-mainnet.pulsechainsafe.com https://api.scan.pulsechain.com"

/**
 * CSP Header notes:
 * For safe apps we have to allow img-src * and frame-src *CSP
 * connect-src * because the RPCs are configurable (config service)
 * style-src unsafe-inline for our styled components
 * script-src unsafe-eval is needed by next.js in dev mode, otherwise only self
 * unsafe-inline is needed for gtm https://developers.google.com/tag-platform/tag-manager/web/csp
 * frame-ancestors can not be set via meta tag
 */
export const ContentSecurityPolicy = `
 default-src 'self';
 connect-src 'self' https://www.google-analytics.com https://ssl.google-analytics.com https://www.googletagmanager.com https://*.ingest.sentry.io https://sentry.io wss://relay.walletconnect.com https://*.walletconnect.com https://verify.walletconnect.org ${
   !IS_PRODUCTION || CYPRESS_MNEMONIC ? nonProductionURLs : productionURLs
 };
 script-src 'self' 'unsafe-eval' 'unsafe-inline' https://www.google-analytics.com https://ssl.google-analytics.com https://www.googletagmanager.com https://*.ingest.sentry.io https://sentry.io wss://relay.walletconnect.com https://*.walletconnect.com https://verify.walletconnect.org ${
   !IS_PRODUCTION || CYPRESS_MNEMONIC ? nonProductionURLs : productionURLs
 };
 frame-src 'self' https://www.google-analytics.com https://ssl.google-analytics.com https://www.googletagmanager.com https://*.ingest.sentry.io https://sentry.io wss://relay.walletconnect.com https://*.walletconnect.com https://verify.walletconnect.org ${
   !IS_PRODUCTION || CYPRESS_MNEMONIC ? nonProductionURLs : productionURLs
 };
style-src 'self' 'unsafe-inline';
 font-src 'self' data:; 
 worker-src 'self' blob:;
 img-src * data:;
`
  .replace(/\s{2,}/g, ' ')
  .trim()

export const StrictTransportSecurity = 'max-age=31536000; includeSubDomains'